export const VALIDATIONS = {
  MAX_NAME: 50,
  MAX_TITLE: 150,
  MAX_DESCRIPTION: 250,
  MIN_DESCRIPTION: 0,
  MAX_PHONE: 10,
};

export const ROLE = {
  LEADER: 0,
  MEMBER: 1,
  ADMIN: 2,
};

export const TASK_TYPE = {
  S: 1,
  M: 2,
  L: 3,
};

export const USER_STATUS = {
  ALL: -1,
  ACTIVE: 0,
  INVITEE: 1,
  SUSPENDED: 2,
  DELETED: 3,
};

export const USER_STATUS_LIST = {
  [USER_STATUS.ACTIVE]: "Active",
  [USER_STATUS.SUSPENDED]: "Not Active",
  [USER_STATUS.INVITEE]: "Invited"
};

export const TASK_TYPE_LIST = ["S", "M", "L"];
export const TASK_TYPE_LIST_CAP = ["Small", "Medium", "Large"];

export const QUESTIONS_TYPE = {
  LIKERT: "likertScale",
  SELECTION: "selection",
};

export const MESSAGES = {
  VERIFICATION_INFO:
    "A verification link is sent into your email. Check your email & click the link to verify your email to login.",
  FORGOT_EMAIL: "A reset password link is sent into your email.",
  USER_STATUS_UPDATE: "User status updated successfully!",
  EMAIL_VERIFICATION_FAILURE: "The email verification link has been expired!",
  CONTACT_US:
    "We appreciate it and we will contact your as soon as possible to answer your questions.",
  FILE_SIZE_ERROR: "File size is greater than maximum limit i.e. 5MB.",
};

export const TEAM_LEADER_POSITION = "Team Leader";

export const MAX_FILE_SIZE = 5120; // 5MB;

export const FILE_SIZE = 1024;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// export const BASE_URL = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_API_URL || "http://157.230.77.223:3000/"; //dev
// export const BASE_URL = process.env.REACT_APP_API_URL || "http://146.190.10.208:3000/"; //qa
// export const BASE_URL = "http://188.166.136.114:3000/"; //stg

export const FUTURE_SKILLS = {
  data: [
    {
      _id: "1",
      skill: "Communication",
    },
    {
      _id: "2",
      skill: "Organization skills",
    },
    {
      _id: "3",
      skill: "Time management",
    },
    {
      _id: "4",
      skill: "Emotional Intelligence",
    },
    {
      _id: "5",
      skill: "Team Player Attitude",
    },
    {
      _id: "6",
      skill: "Growth Mindset",
    },
    {
      _id: "7",
      skill: "Openness to Feedback",
    },
    {
      _id: "8",
      skill: "Adaptability",
    },
    {
      _id: "9",
      skill: "Active Listening",
    },
    {
      _id: "10",
      skill: "Work Ethic",
    },
  ],
  selection: {
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false,
    "7": false,
    "8": false,
    "9": false,
    "10": false,
  },
};

export const NOTIFICATION_TYPE = {
  acceptInvitation: 1,
  acceptTask: 2,
  completeTask: 3,
  notFilledQuestionnaire: 4,
  notPayingOutstandingAmount: 5,
  completeTaskFeedback: 6,
  newFeatureAndUpdates: 7,
  taskAssignment: 8,
  memberNotFillingQuestionnaire: 9,
};

export const PROFILE_VIEW = {
  PROFILE: 1,
  SETTINGS: 2,
  SIGNOUT: 3,
};

export const TASK_STATUS = {
  STARTED: 0,
  INPROGRESS: 1,
  DONE: 2,
  TASKLEFT: 3,
  PLANTASK: 5,
  SUGGESTION: 6,
};

export const NOT_STARTED_STATUS = {
  NOTASSIGNED: 3,
  ASSIGNEDPENDING: 4,
};

export const TASK_CAPACITY = {
  SMALL: 3,
  MEDIUM: 1,
  LARGE: 1,
};

export const MEMBER_TASK_FILTER = {
  NONE: 0,
  ASSIGNED: 1,
  DONE: 2,
};

export const TASK_THERSHOLD_VALUE = 20;

export const MEMBER_GPAPH_COLOR = {
  backgroundColor: "rgba(255, 151, 151, 0.3)",
  borderColor: "rgba(255, 151, 151, 1)",
  borderWidth: 1,
};

export const MEMBERS_COLORS = [
  {
    backgroundColor: "rgba(251, 236, 182, 0.3)",
    borderColor: "rgba(251, 236, 182, 1)",
    className: "_yellow",
  },
  {
    backgroundColor: "rgba(62, 237, 201, 0.3)",
    borderColor: "rgba(62, 237, 201, 1)",
    className: "_green",
  },
  {
    backgroundColor: "rgba(227, 162, 237, 0.3)",
    borderColor: "rgba(227, 162, 237, 1)",
    className: "_pink",
  },
];
