import { Info } from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import memberSkillImg from "../../../assets/images/createMember.svg";
import { useTranslation } from "react-i18next";

export default function TeamCreationInfo() {
  const { t } = useTranslation();
  return (
    <Info
      title={ t("creatingyour")}
      spantitle="team"
      subTitle="teamCreationSubTitile"
      image={memberSkillImg}
      description1="teamCreationDescription1"
      // description2="teamCreationDescription2"
      // description3="teamCreationDescription3"
      // description4="teamCreationDescription4"
      buttonText="LetsGo"
      nextUrl={ROUTE_NAVIGATION_PATH.CREATE_TEAM}
    />
  );
}
