import { Info } from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import memberSkillImg from "../../../assets/images/create-task.svg";
import { useTranslation } from "react-i18next";

export default function TaskCreationInfo() {
  const { t } = useTranslation();
  return (
    <Info
      title={t("createNew")}
      spantitle="Task"
      subTitle="taskCreationSubTitile"
      image={memberSkillImg}
      description1="taskCreationdescription1"
      description2="taskCreationdescription2"
      // description3='taskCreationdescription3'
      // description4="taskCreationdescription4"
      // description5='taskCreationdescription5'
      buttonText="LetsGo"
      nextUrl={ROUTE_NAVIGATION_PATH.CREATE_TASK}
    />
  );
}
