import { Info } from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import requirementForTask from "../../../assets/images/requirementForTask.svg";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RequirementForTaskInfo() {
  const { taskId } = useParams();
  const { taskName } = useParams()
  const { t } = useTranslation();
  return (
    <Info
      title={t("What")}
      spantitle="requirements"
      nextTitle="are needed for the task?"
      subTitle="Fill out the questionnaire to describe the task in more details"
      image={requirementForTask}
      description1="requiremnetsInfo1"
      description2="requiremnetsInfo2"
      buttonText="LetsGo"
      nextUrl={`${ROUTE_NAVIGATION_PATH.TASK_QUESTIONNAIRE}/${taskId}/${taskName}`}
    />
  );
}
