import ProgressBar from "react-bootstrap/ProgressBar";
import { Dimensions } from "../../api/models/Dimensions";

export default function SkillsMetric({
  dimensions,
}: {
  dimensions: Dimensions;
}) {
  return (
    <ul>
      {dimensions.map((dimension) => (
        <li key={dimension.dimensionId}>
          <p>{dimension.taskDimensionName}</p>
          <div className="progress-bg-text">
          
            <ProgressBar now={+dimension.average.toFixed(1)} label={`${dimension.average.toFixed(1)}%`} visuallyHidden/>
          </div>
          <span className="progress-percentage">{dimension.average.toFixed(1)}%</span>
        </li>
      ))}
    </ul>
  );
}
