import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import profileImg from "../../../assets/images/icon-profile.png";
import badges from "../../../assets/images/Badge.svg";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { team } from "../../../api";
import { Member } from "../../../api/models/User";
import { TEAM_LEADER_POSITION } from "../../../constants";
import { AppSettings, EditTeamMember } from "../../../components";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";

export default function LeaderSetting() {
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [details, setUpdatedDetails] = useState<Member | null>(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    team
      .getLeaderProfile()
      .then((res) => {
        res.data.userInfo["position"] = TEAM_LEADER_POSITION;
        setDetailsToShow(res.data.userInfo);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: "Error",
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    setDetailsToShow(updatedData);
    setUpdatedDetails(null);
  };
  function formatDate(value: any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return date.toLocaleDateString("en-GB").split("/").join("-");
  }

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <h1>
              {t("My")}
              <span> {t("Setting")}</span>
            </h1>
            <p>{t("updateSettingMsg")}</p>
            {/* <div className="logout" onClick={handleClick}>
              <p className="header">Logout</p>
            </div> */}
          </div>
          {detailsToShow ? (
            <div className="setting-container">
              <Col md={12} className="setting-info-left">
                <div className="setting-info">
                  <div className="heading">{t("Account")} </div>
                  <div className="task-manage-team-wrapper">
                    <div className="table-content">
                      {user.invoiceId === null ? (
                        "Account not active"
                      ) : (
                        <table>
                          <thead>
                            <tr>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Last Invoice")}</th>
                              <th>{t("Invoice Date")}</th>
                              <th>{t("Permitted Members")}</th>
                              <th>{t("Current Members")}</th>
                              <th>{t("Plan Start Date")}</th>
                              <th>{t("Plan End Date")}</th>
                            </tr>
                          </thead>
                          <tbody style={{ cursor: "pointer" }}>
                            <tr>
                              <td>
                                <div className="profile-info">
                                  <div className="_profile-img">
                                    <img
                                      src={
                                        user?.image
                                          ? user?.image
                                          : profileImg
                                      }
                                      onError={(e: any) => {
                                        e.target.src = profileImg;
                                      }}
                                      alt="ProfileImg"
                                    />
                                    <img
                                      src={badges}
                                      alt="profile"
                                      className="badges"
                                    />
                                  </div>
                                  <p>
                                    {user.name} {user.surname}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p className="emailuser">{user.email}</p>
                              </td>
                              <td>
                                {user.invoiceId.invoiceAmount
                                  ?  user.invoiceId.invoiceAmount + " €"
                                  : ""}
                              </td>
                              <td>
                                {user.invoiceId.invoiceDate
                                  ? formatDate(user.invoiceId.invoiceDate)
                                  : ""}
                              </td>
                              <td>
                                {user.invoiceId.permittedMembers
                                  ? user.invoiceId.permittedMembers
                                  : 0}
                              </td>
                              <td>
                                {user.invoiceId.addedMembers
                                  ? user.invoiceId.addedMembers
                                  : 0}
                              </td>
                              <td>
                                {user.invoiceId.planStartDate
                                  ? formatDate(user.invoiceId.planStartDate)
                                  : ""}
                              </td>
                              <td>
                                {user.invoiceId.planEndDate
                                  ? formatDate(user.invoiceId.planEndDate)
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={5} className="app-setting-content">
                <AppSettings />
              </Col>
            </div>
          ) : (
            <div className="task-myprofile-wrapper">
              <div className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        </section>
      </Row>
      <EditTeamMember
        detail={details}
        handleClose={() => setUpdatedDetails(null)}
        updateListItem={updateListItem}
      />
    </>
  );
}
