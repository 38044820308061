import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import NoData from "../../../NoData";
import profileIcon from "../../../../assets/images/iconprofile.png";
import {
  MEMBERS_COLORS,
  TASK_CAPACITY,
  TASK_TYPE,
} from "../../../../constants";
import badgeImg from "../../../../assets/images/Badge.svg";
import arrowTop from "../../../../assets/images/arrowTop.svg";
import arrowBottom from "../../../../assets/images/arrowBottom.svg";

export default function SuggestedMemberList({
  setAssisgnMemberId,
  suggestions = [],
  activeSuggestion,
}: {
  setAssisgnMemberId: (memberId: string, key: number) => void;
  suggestions: any;
  activeSuggestion: number;
}) {
  const { t } = useTranslation();
  const [activeSuggestionDim, setActiveSuggestionDim] = useState<number | null>(
    0
  );
  function setDimesionOpenner(key: number) {
    key === activeSuggestionDim
      ? setActiveSuggestionDim(null)
      : setActiveSuggestionDim(key);
  }
  return (
    <Col md={12} lg={4} className="contact-left-panel">
      <h3>{t("Top-3Employees")}</h3>

      {suggestions ? (
        <div className="user-profile-desc">
          {suggestions.map((suggestion: any, key: any) => (
            <div
              key={suggestion.id}
              className={`user-profile-panel ${
                key === activeSuggestion ? "" : "_with-white-bg"
              }`}
              onClick={() => {
                setAssisgnMemberId(suggestion.teamMemberId._id, key);
                setDimesionOpenner(key);
              }}
            >
              <div className="profile-desc">
                <div className="user-profile">
                  <img
                    src={
                      suggestion?.teamMemberId?.image
                        ? suggestion?.teamMemberId?.image
                        : profileIcon
                    }
                    onError={(e: any) => {
                      e.target.src = profileIcon;
                    }}
                    alt="ProfileImg"
                  />
                  <img src={badgeImg} alt="badge" className="badges" />
                </div>
                <div className="user-info">
                  <h4>
                    {suggestion?.teamMemberId?.name}{" "}
                    {suggestion?.teamMemberId?.surname}
                  </h4>
                  <p>{suggestion?.teamMemberId?.position}</p>
                </div>
                <div
                  className={`rank-info ${MEMBERS_COLORS?.[key]?.className}`}
                >
                  Top {key + 1}
                </div>
              </div>
              <div className="task-capacity-n">
                <div className="task-capacity">
                  <div className="tsk-capacity-c-bt _capacity-n">
                    {t("Capacity")}:
                  </div>
                  <div className="tsk-capacity-c-bt _small-n">
                    S{" "}
                    {Object.keys(suggestion?.teamMemberTaskCount).length === 0
                      ? 0
                      : suggestion?.teamMemberTaskCount[TASK_TYPE.S] ===
                        undefined
                      ? 0
                      : suggestion?.teamMemberTaskCount[TASK_TYPE.S]}
                    /{TASK_CAPACITY.SMALL}
                  </div>
                  <div className="tsk-capacity-c-bt _medium-n">
                    M{" "}
                    {Object.keys(suggestion?.teamMemberTaskCount).length === 0
                      ? 0
                      : suggestion?.teamMemberTaskCount[TASK_TYPE.M] ===
                        undefined
                      ? 0
                      : suggestion?.teamMemberTaskCount[TASK_TYPE.M]}
                    /{TASK_CAPACITY.MEDIUM}
                  </div>
                  <div className="tsk-capacity-c-bt _large-n">
                    L{" "}
                    {Object.keys(suggestion?.teamMemberTaskCount).length === 0
                      ? 0
                      : suggestion?.teamMemberTaskCount[TASK_TYPE.L] ===
                        undefined
                      ? 0
                      : suggestion?.teamMemberTaskCount[TASK_TYPE.L]}
                    /{TASK_CAPACITY.LARGE}
                  </div>
                </div>
              </div>
              <div
                className={`matching-info ${
                  key === activeSuggestionDim ? "" : "_blue-color"
                }`}
                onClick={() => setDimesionOpenner(key)}
              >
                <div className="infoheading">
                  {t("Matching")} {suggestion.suggestionPercent.toFixed(2)}%
                </div>
                {activeSuggestionDim === key ? (
                  <img src={arrowTop} alt="arrow" />
                ) : (
                  <img src={arrowBottom} alt="arrow" />
                )}
              </div>
              {activeSuggestionDim === key ? (
                <div className={`all-info-detail text-color-white`}>
                  <ul>
                    {suggestion?.teamMemberId?.skilldimension?.map((d: any) => (
                      <li key={d.skillDimensionName}>
                        <p className="content">
                          {d.skillDimensionName !== "Szenario-Denken"
                            ? d.skillDimensionName?.replace("-", "")
                            : d.skillDimensionName}
                        </p>
                        <p className="percentage-text">
                          {d.personalityValue.toFixed(1)}%
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <NoData />
      )}
    </Col>
  );
}
