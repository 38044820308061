import React, {  useState } from "react";
import Row from "react-bootstrap/Row";
import iconDown from "../../../assets/images/arrow-down.svg";
import iconUp from "../../../assets/images/arrow-up.svg";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Spinner from "react-bootstrap/Spinner";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import profileImg from "../../../assets/images/icon-profile.png";
import badges from "../../../assets/images/Badge.svg";
import { USER_STATUS } from "../../../constants";
import { admin } from "../../../api";
import {
  SearchBar,
  NoData,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type SortType = Record<string, 1 | -1>;

const TableHeading = ({
  text,
  keyName,
  sortBy,
  updateSortValue,
}: {
  text: string | React.ReactNode;
  keyName: string;
  sortBy?: SortType;
  updateSortValue: (value: SortType) => void;
}) => {
  const doSort = () => {
    if (sortBy?.[keyName]) {
      updateSortValue({ [keyName]: sortBy?.[keyName] * -1 } as SortType);
    } else {
      updateSortValue({ [keyName]: -1 });
    }
  };
  return (
    <>
      {text}
      <span className="arrow" onClick={doSort}>
        <img
          src={iconUp}
          alt="arrow"
          className={`toparrow ${
            sortBy?.[keyName] && sortBy?.[keyName] === -1 && "active"
          }`}
        />
        <img
          src={iconDown}
          alt="arrow"
          className={`downarrow ${
            sortBy?.[keyName] && sortBy?.[keyName] === 1 && "active"
          }`}
        />
      </span>
    </>
  );
};

export default function ManageLeader() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<SortType>();
  const navigate  = useNavigate();
  const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: admin.leaderList,
      page,
      search,
      sortBy,
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const doSearch = (keyword: string) => {
    if (keyword !== search) {
      setPage(1);
      setSearch(keyword);
    }
  };

  const doSort = (sort: SortType) => {
    setPage(1);
    setSortBy(sort);
  };

  function formatDate(value:any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return  date.toLocaleDateString('en-GB').split('/').join('-');
  }

  function handleClick(value:any, leaderId:any) {
    const invoiceId = value ? value._id  : 0;
    navigate(ROUTE_NAVIGATION_PATH.MANAGE_INVOICE+'/'+invoiceId+'/'+leaderId);
  }

  return (
    <>
      <Row>
        <section className="main-container shrink-width" ref={rootRef}>
          <div className="heading-common">
            <h1>
              {t("manageTeamheading")} <span> {t("Team")}</span>!
            </h1>
            <p>{t("mngLeaderHeading")}</p>
          </div>

          {/* {items.length && loading ? */}
          <div className="task-manage-team-wrapper">
            <SearchBar
              doSearch={doSearch}
              placeHolder={t("Search by name, email or postition")}
            />

            <div className="table-scrollbar scrollbar">
              <div className="table-content">
                {!items.length && !loading ? <NoData /> : null}
                <table>
                  {items.length ? (
                    <thead>
                      <tr>
                        <th>
                          <TableHeading
                            text="Leader Name"
                            keyName="name"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>Leader Email</th>
                        <th>Last Invoice</th>
                        <th>
                          <TableHeading
                            text="Invoice Date"
                            keyName="invoiceDate"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>Permitted Members</th>
                        <th>Current Members</th>
                        <th>
                          <TableHeading
                            text={t("Plan Start Date")}
                            keyName="planStartDate"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>
                          <TableHeading
                            text={t("Plan End Date")}
                            keyName="planEndDate"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>
                          <TableHeading
                            text={t("Status")}
                            keyName="status"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                      </tr>
                    </thead>
                  ) : null}

                  {items.map((user) => (
                    <tbody style={{'cursor':'pointer'}}>
                      <tr onClick={()=>handleClick(user.invoiceId, user._id)}>
                        <td>
                          <div className="profile-info">
                            <div className="_profile-img">
                              <img
                                src={user?.image ? user?.image : profileImg}
                                onError={(e: any) => {
                                  e.target.src = profileImg;
                                }}
                                alt="ProfileImg"
                              />
                              <img src={badges} alt="profile" className="badges" />
                            </div>
                            <p>{user.name} {user.surname}</p> 
                          </div>
                        </td>
                        <td><p className="emailuser">{user.email}</p></td>
                        <td>{ user.invoiceAmount ? user.invoiceAmount + ' €': ''}</td>
                        <td>{user.invoiceDate ? formatDate(user.invoiceDate) : ''}</td>
                        <td>{user.permittedMembers ? user.permittedMembers: 0}</td>
                        <td>{user.addedMembers ? user.addedMembers: 0}</td>
                        <td>{user.planStartDate ? formatDate(user.planStartDate): ''}</td>
                        <td>{user.planEndDate ? formatDate(user.planEndDate): ''}</td>
                        <td>
                          {user.status === USER_STATUS.ACTIVE ||
                          user.status === USER_STATUS.INVITEE
                            ? "Active"
                            : "Not active"}
                        </td>
                      </tr>
                  </tbody>
                  ))}
                  {/* <div className="bottom-graidient" ></div> */}
                  {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* : <NoData /> } */}
        </section>
      </Row>
    </>
  );
}
